@import "./../../node_modules/antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  scrollbar-width: thin;
  scrollbar-color: #555 #f1f1f1;
  font-family: "Montserrat", sans-serif;
}
//scroll bar styles
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//table styles
.ant-table {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.ant-table-container table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.ant-table-container table thead tr:last-child th:last-child {
  border-top-right-radius: 10px;
}

.ant-table-tbody tr:last-child td {
  border-bottom: none;
}

// rounded cards
.ant-card-bordered {
  border-radius: 10px;
  overflow: hidden;
}

.ant-card-head {
  border-radius: 10px 10px 0 0;
}

// select input
.ant-select-selector {
  border-radius: 5px !important;
}

input,
textarea {
  border-radius: 5px !important;
}

// some general style classes

.radius-5 {
  border-radius: 5px;
}

//hide time-picker ok button
.ant-picker-ok {
  display: none !important;
}

//rounded modals
.ant-modal-content {
  border-radius: 10px !important;
}
//hide collapse header padding
.ant-collapse-header {
  padding: 0 !important;
}

.ant-typography-secondary {
  color: "#64748B";
}

// alternate row colors for tables
.table-striped-rows tr:nth-child(2n) td {
  background-color: #f6faff;
}

.ant-tooltip-inner {
  a {
    color: #fff;
  }
}

.icon-date-picker .ant-picker-input > input {
  display: none;
}
.ant-popover {
  z-index: 2001 !important; /* Default value is 1030 */
}

.ant-tooltip {
  z-index: 2000 !important; /* Default value is 1070 */
}

@menu-dark-inline-submenu-bg: transparent;@menu-dark-item-active-bg: rgba(255,255,255,0.2);